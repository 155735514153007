<template>
  <v-container fluid>
    <div class="header">
      <v-row no-gutters>
        <v-col cols="8" md="6" offset-md="1" lg="6" offset-lg="2" xl="6">
          <Container>
            <h1>Vertrouwde zorg voor optimale bewegingsvrijheid</h1>
          </Container>
        </v-col>
      </v-row>
    </div>
    <v-row v-for="(card, index) in cards" :key="index" no-gutters>
      <v-col cols="12" md="10" offset-md="1" lg="6" offset-lg="2" xl="5">
        <div class="card">
          <div class="content">
            <h2>
              <v-icon class="mr-3" color="#7f8274" size="30">{{
                card.icon
              }}</v-icon
              >{{ card.title }}
            </h2>
            <p class="hidden-sm-and-down">
              {{ card.textLong }}
            </p>
            <p class="hidden-md-and-up">
              {{ card.text }}
            </p>
            <p v-if="card.link" class="mt-3 hidden-md-and-up">
              <v-btn small color="#5D768E" dark :href="card.link" target="new">
                <v-icon small>{{ card.icon }}</v-icon>
                <span class="white--text ml-2">{{ card.linkText }}</span>
              </v-btn>
            </p>
            <p v-if="card.link" class="mt-3 hidden-sm-and-down">
              <v-btn color="#5D768E" large dark :href="card.link" target="new">
                <!-- <v-icon small>{{ card.icon }}</v-icon> -->
                <span class="white--text ml-2">{{ card.linkTextLong }}</span>
              </v-btn>
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container>
          <h2><em>Osteo’s</em> kijkt verder dan uw klacht</h2>
          <p>
            Osteo’s staat voor de hoogste kwaliteit osteopathische zorg met een
            laagdrempelige en ‘no-nonsense’ aanpak. Een persoonlijke benadering
            staat hierin centraal. Wij nemen de tijd om uw gezondheidsprobleem
            in kaart te brengen en stellen samen met u een persoonlijk
            behandelplan op. Onze gecertificeerde osteopaten voldoen dan ook aan
            de hoogste kwaliteitseisen, waardoor u in aanmerking komt voor
            vergoeding bij een aanvullende verzekering. We verwelkomen u graag
            op één van onze locaties en helpen u weer regie te krijgen over uw
            gezondheid.
          </p>
        </Container>
      </v-col>
    </v-row>
    <div class="banner">
      <!-- <v-carousel
        cycle
        :show-arrows="false"
        hide-delimiters
        hide-delimiter-background
        height="100%"
      >
        <v-carousel-item v-for="(review, index) in reviews" :key="index">
          <h2>
            "{{ review.text }}"
             <span>-{{ review.name }}</span> 
            <v-rating
              hover
              length="5"
              readonly
              color="#fff"
              size="20"
              :value="review.rating"
            ></v-rating>
          </h2>
        </v-carousel-item>
      </v-carousel> -->
      <h2>MEER DAN 7541 TEVREDEN PATIENTEN</h2>
    </div>
  </v-container>
</template>

<script>
import Container from "../components/Container.vue";

// @ is an alias to /src
export default {
  name: "Home",
  components: { Container },
  data: () => ({
    cards: [
      {
        title: "Online afspraken inplannen",
        icon: "mdi-calendar",
        text: "Snel & gemakkelijk",
        textLong: "Bij Osteo's maakt u gemakkelijk online een afspraak",
        link:
          "https://altagenda.crossuite.com/osteos/o/74z2c47423v2o2543444h4w2a4v294g4d4x2x274c4s2x2y2u21353r264x2y2t203u22343r2d4v2s274x244d4s203o2b4c4d474z294v224 ",
        linkText: "inplannen",
        linkTextLong: "Maak direct een afspraak"
      },
      {
        title: "Erkende & ervaren osteopaten",
        icon: "mdi-account-check",
        text: "Erkend volgens de strengste kwaliteitseisen",
        textLong:
          "Al onze osteopaten voldoen aan de strengste kwaliteitseisen en zijn geregistreerd bij de NRO"
      },
      {
        title: "Persoonlijke benadering",
        icon: "mdi-heart",
        text: "U bent meer dan alleen uw klacht",
        textLong: "Niet alleen de klacht, maar u als geheel staat centraal"
      },
      {
        title: "Vergoeding mogelijk",
        icon: "mdi-piggy-bank",
        text: "Wij zijn aangesloten bij de NRO en NVO",
        textLong:
          "Wij voldoen aan alle eisen van zorgverzekeraars om in aanmerking te komen voor vergoeding"
      }
    ],
    reviews: [
      {
        text:
          "Honderd procent tevreden over mijn behandeling en het resultaat.",
        name: "Lisette Verlegh",
        rating: 5
      },
      { text: "Amet sit dolor ipsum lorem", name: "Miljak Hrvoje", rating: 4 }
    ]
  }),
  mounted: function() {}
};
</script>
<style lang="less" scoped>
@media (orientation: landscape) {
  .header {
    height: 50vw;
    line-height: 20vw;
    margin-bottom: -30vh;
  }
  .banner {
    height: 20vw;
    line-height: 20vw;
  }
}

@media (orientation: portrait) {
  .header {
    height: 60vh;
    line-height: 30vh;
    margin-bottom: -10vh;
  }
  .banner {
    height: 30vh;
    line-height: 30vh;
  }
}

h2 {
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;

  em {
    font-style: normal;
    color: #7f8274;
  }
}

.header {
  background-position: center center;
  background-size: cover;
  width: 100vw;
  background-image: url(../assets/dancing_v2.jpg);
  h1 {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    text-shadow: #fff 0px 0px 2px;
  }
}

.banner {
  background-position: center center;
  background-size: cover;
  width: 100vw;
  background-image: url(../assets/erasmus.jpg);
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;

  h2 {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    color: #fefcfd;
    font-size: 1.2rem;

    span {
      color: #fefcfd;
      font-size: 1rem;
      text-transform: none;
    }
  }
}

.card {
  background-color: #cacfb8;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  padding: 0.7rem;
  // min-height: calc(40px + 4rem);
  overflow: hidden;
  display: flex;
  margin-bottom: 1rem;

  .icon {
    padding: 1rem;
  }

  a {
    &:link,
    &:hover,
    &:visited,
    &:active {
      text-decoration: none;
    }
  }

  .content {
    padding: 0.5rem;
    color: #434343;

    h2 {
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin-bottom: 0.5rem;
      text-transform: none;
    }
    p {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1600px) {
  .header {
    h1 {
      font-size: 3rem;
    }
  }
}

@media (min-width: 1904px) {
  .header {
    h1 {
      font-size: 4rem;
    }
  }
  .banner {
    h2 {
      font-size: 3rem;
      span {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
