<template>
  <v-app>
    <v-main>
      <header>
        <router-link to="/"
          ><img
            src="./assets/text_logo_black_horizontal_scaled.png"
            alt="osteo's logo"
            width="350"
            height="82"
            :class="{
              'md-logo':
                $vuetify.breakpoint.name == 'lg' ||
                $vuetify.breakpoint.name == 'xl'
            }"
          />
        </router-link>
        <div class="hamburger hidden-md-and-up" @click="showMenu = !showMenu">
          <v-icon v-if="!showMenu" x-large>mdi-menu</v-icon>
          <v-icon v-else x-large>mdi-close</v-icon>
        </div>
        <nav class="hidden-md-and-up" v-show="showMenu">
          <router-link
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :to="menuItem.path"
            >{{ menuItem.title }}</router-link
          >
        </nav>
        <nav class="hidden-sm-and-down desktop">
          <router-link
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :to="menuItem.path"
            >{{ menuItem.title }}</router-link
          >
        </nav>
      </header>
      <section>
        <router-view></router-view>
      </section>
      <footer>
        <v-btn
          fab
          elevation="0"
          color="#e3e3e3"
          href="https://www.instagram.com/osteos.nl/"
          target="new"
          aria-label="Osteo's instagram"
        >
          <v-icon x-large color="#434343">mdi-instagram</v-icon>
        </v-btn>
        <br />
        <a href="./avg.pdf" target="new">privacy policy</a>
      </footer>
    </v-main>
    <v-bottom-navigation app fixed class="hidden-md-and-up">
      <v-btn
        x-large
        href="https://altagenda.crossuite.com/osteos/o/74z2c47423v2o2543444h4w2a4v294g4d4x2x274c4s2x2y2u21353r264x2y2t203u22343r2d4v2s274x244d4s203o2b4c4d474z294v224 "
        target="new"
        block
      >
        <span>Maak een afspraak</span>

        <v-icon color="#fff">mdi-calendar</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    showMenu: false,
    menuItems: [
      { title: "Home", path: "/" },
      { title: "Osteopathie", path: "/osteopathie" },
      { title: "Werkwijze", path: "/werkwijze" },
      { title: "Tarieven", path: "/tarieven" },
      { title: "Over ons", path: "/over-ons" },
      { title: "Contact", path: "/contact" }
    ]
  }),
  watch: {
    $route() {
      this.showMenu = false;
    }
  }
};
</script>
<style lang="less">
* {
  color: #434343;
  font-weight: 300;
}
body {
  background-color: #fefcfd;
}
.container {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
header {
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fefcfd;
}
header > a > img {
  width: 200px;
  height: auto;
}
header > a > .md-logo {
  width: 350px;
  height: auto;
}
header > .hamburger {
  float: right;
  cursor: pointer;
}

header > nav {
  margin-top: 1rem;
  text-transform: uppercase;
}

header > nav > a {
  padding: 0.2rem;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  display: block;
}

header > nav.desktop {
  margin-top: 0rem;
  display: block;
  float: right;
  line-height: 82px;
}

header > nav.desktop > a {
  display: inline;
  margin-right: 1rem;
}

header > nav > a {
  color: #434343;
  text-decoration: none;
}
header > nav > a:hover {
  text-decoration: underline;
}

header > nav > .router-link-exact-active {
  text-decoration: underline;
}

section {
  min-height: calc(100vh - 82px - 171px);
}

footer {
  text-align: center;
  background-color: #e3e3e3;
  padding: 1rem;
  padding-top: 2rem;
  line-height: 3rem;

  a {
    color: #434343;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
    &.v-btn {
      text-decoration: none;
    }
  }
}

.theme--light.v-bottom-navigation {
  background-color: #5d768e;
  span {
    color: #fff;
  }
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: #5d768e;
}
</style>
